let produtosVitrine = [];
const carouselHTML = '';

const collectionId = document.currentScript.getAttribute('data-collectionid');
const vtexUrl = document.currentScript.getAttribute('data-vtexurl');
const vtexUrlFormatted = vtexUrl.replace(/\[|\]/g, '');

// Função para reestruturar a URL de imagem com parâmetro de largura x altura (200-200)
function convertImageUrl(imageUrl) {
  const regex = /\/ids\/(\d+)/; // Expressão regular para capturar o número após "/ids/"
  const match = imageUrl.match(regex); // Procura por uma correspondência na URL

  if (match) {
    const id = match[1]; // Captura o número encontrado na URL
    const modifiedUrl = imageUrl.replace(regex, `/ids/${id}-300-300`); // Constrói a URL modificada
    return modifiedUrl;
  }

  // Retorna a URL original caso não seja possível modificá-la
  return imageUrl;
}

function buildCarouselProduct(produto) {
  const { link: productLink, productName, items } = produto;
  const { sellers, images } = items[0];
  const { imageUrl: productImage } = images[0];
  const modifiedImageUrl = convertImageUrl(productImage);

  const adjustedProductLink = (originalUrl, newBaseUrl) => {
    const original = new URL(originalUrl);
    const newPath = original.pathname;
    const newUrl = new URL(newBaseUrl);

    newUrl.pathname = newPath;
    return newUrl.href;
  };

  const finalProductLink = adjustedProductLink(productLink, vtexUrlFormatted);

  // No JSON vinha mais de uma loja por produto.
  // Aqui estou pegando a loja que é definida como 'padrão' (sellerDefault = true)
  const sellerDefault = sellers.find(seller => seller.sellerDefault === true);
  const isFreeShipping = false;

  let maxInstallments = 0;
  let installmentValue = Infinity;
  let sellerName = '';
  let productPrice = '';
  let haveDiscount = false;
  let discountValue = 0;

  if (sellerDefault) {
    const { commertialOffer } = sellerDefault;
    const { ListPrice, Price, Installments } = commertialOffer;
    const pricesAreDifferent = ListPrice && ListPrice !== Price;

    sellerName = sellerDefault.sellerName;
    haveDiscount = pricesAreDifferent && ListPrice > Price;
    discountValue = Math.round((1 - Price / ListPrice) * 100);

    productPrice = Price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    for (const installment of Installments) {
      if (installment.NumberOfInstallments > maxInstallments) {
        maxInstallments = installment.NumberOfInstallments;
      }

      if (installment.Value < installmentValue) {
        installmentValue = installment.Value;
      }
    }

    if (installmentValue !== Infinity) {
      installmentValue = installmentValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  }

  const hasDiscountHTML = haveDiscount
    ? `<div class="vtex-carousel__discount">${discountValue}% OFF</div>`
    : '';

  const freeShippingHTML = isFreeShipping
    ? '<div class="vtex-carousel__free-shipping">Frete Grátis</div>'
    : '';

  const productHTML = `
	  <a href="${finalProductLink}" class="vtex-carousel__item vtex-carousel">
		<div class="vtex-carousel__image__container">
		  <figure class="vtex-carousel__figure">
			<img class="vtex-carousel__image" data-lazy="${modifiedImageUrl}" />
			<img class="vtex-carousel__image--mobile" data-lazy="${modifiedImageUrl}" />
		  </figure>
		  <div class="vtex-carousel__info">
			${hasDiscountHTML}
		  </div>
		</div>
		<div class="vtex-carousel__content">
		  <span class="vtex-carousel__store">${sellerName}</span>
		  <h3 class="title">${productName}</h3>
		  ${
        haveDiscount
          ? `<p class="vtex-carousel__fullprice"><del>de ${sellerDefault.commertialOffer.ListPrice.toLocaleString(
              'pt-BR',
              { style: 'currency', currency: 'BRL' },
            )} por</del></p>`
          : ''
      }
		  <span class="cta">${productPrice}</span>
		  <p class="vtex-carousel__installments">${maxInstallments}x de ${installmentValue} sem juros</p>
		</div>
	  </a>`;

  return productHTML;
}

function populateCarousel(produtosVitrine, posicaoVitrine) {
  if (produtosVitrine.length > 0 && produtosVitrine !== '#SEMPRODUTOS#') {
    const carouselHTML = `
		  <div id="${posicaoVitrine}__wrapper" class="vtex-carousel__wrapper">
			<div id="${posicaoVitrine}__list" class="vtex-carousel__list">
			  ${produtosVitrine
          .slice(0, 15) // Quantidade máxima de produtos para exibir no carrossel
          .map(produto => buildCarouselProduct(produto))
          .join('')}
			</div>
		  </div>
		`;

    $(`#${posicaoVitrine}--loading`).remove();
    $(`#${posicaoVitrine}`).append(carouselHTML);
  } else {
    console.error('Erro ao popular vitrine VTEX');
  }

  if ($(`#${posicaoVitrine}__list`).length > 0) {
    $(`#${posicaoVitrine}__list`).slick({
      lazyLoad: 'ondemand',
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1.5,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            infinite: true,
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
            arrows: false,
          },
        },
      ],
    });
  }
}

function buildCarousel(posicaoVitrine) {
  const url = g_LumisRoot_href + 'vitrinevtex/get?collectionId=' + collectionId;

  fetch(url, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Erro na solicitação: ' + response.status);
      }
    })
    .then(data => {
      const JSONObject = JSON.parse(data.response);

      if (data.httpcode === 200) {
        produtosVitrine = JSONObject;
      } else {
        produtosVitrine = '#SEMPRODUTOS#';
      }

      populateCarousel(produtosVitrine, posicaoVitrine);
    })
    .catch(error => {
      console.error('Erro consultando vitrine VTEX', error);
    });
}

const posicaoVitrine = document.currentScript.getAttribute('data-posicaoVitrine');
buildCarousel(posicaoVitrine);
